
.navigation_menu{
    padding:7px;
    /* font-size: 17px; */
}
.navigation_menu:hover{
    /* margin-top: 7px; */
    background-color: rgb(210, 204, 215);
    
}

.navigation_link{
    color:rgb(92, 87, 87);
    font-weight: 600;
}

.navigation_menu:hover .navigation_link{
    color: blueviolet;
}
.navigation_div:hover .navigation_link{
    color: blueviolet;
}
.category_sidebar{
    overflow-y: scroll;
}
.cart_input{
    text-align: center;
    width: 2rem;
    margin: 0 2px;
    font-size: 0.9rem;
}
.cart_plus{
    cursor: pointer;
    font-size: 0.7rem;
}
.cart_minus{
    cursor: pointer;
    font-size: 0.7rem;
}
.remove_icon{
    cursor: pointer;
}
.off_canvas_btn{
    position: fixed;
    top: 26.5rem;
    right: 0;
}
.off_canvas_parent{
    position: relative;
    z-index: 1;
}

